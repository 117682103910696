.reports-filter-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem 0.625rem 0.625rem 0.625rem;
  box-sizing: border-box;

  &__items {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 0.4375rem 0;
  }

  &__item {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 0.625rem 0;
  }

  &__item-title {
    margin: 0 0 5px 0;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: white;
  }
  &__item-input {
    box-sizing: border-box;
    width: 100%;
  }

  &__item-dates-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px 8px;
    margin: 0 0 0.625rem 0;
  }
  &__item-dates-title {
    box-sizing: border-box;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: white;
  }
  &__item-dates-input {
    box-sizing: border-box;
    height: 2.5rem;
    width: 100%;
  }

  &__button {
    box-sizing: border-box;
    width: 100%;
    height: 2.5rem;
  }
  &__button-text {
    box-sizing: border-box;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
}
