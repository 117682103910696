.reports {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &__filters {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 1.25rem 0;
  }

  &__list {
    box-sizing: border-box;
    padding: 0 0.625rem;
  }
  &__item {
    box-sizing: border-box;

    height: 3.125rem;
    display: grid;
    grid-template-columns: 0.7fr 0.7fr 1fr 0.8fr 1fr;
    margin-bottom: 8px;
    padding: 0 5px;
    background: #19295a;
    border: 1px solid #08153d;
    border-radius: 5px;
  }
  &__item-td {
    box-sizing: border-box;
    min-width: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: white;
  }

  &__paginator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0 0 0;
  }
}
