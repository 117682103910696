.total {
  padding: 0.5rem 1rem;
  color: #fff;
  &__title {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-size: 1rem;
    justify-content: center;
  }
  &__date-wrapper {
    position: relative;
    input {
      background: #08153D;
      border-radius: 5px;
      color: #fff;
      &::-webkit-calendar-picker-indicator {
        background: transparent;
        color: transparent;
        cursor: pointer;
        height: auto;
        position: absolute;
        z-index: 2;
        right: 0;
        top: 0;
        bottom: 0;
        width: 4rem;
      }
    }
  }
  &__icon {
    position: absolute;
    z-index: 1;
    right: 1.28rem;
    top: 50%;
    transform: translateY(-50%);
    &-svg {
      display: block;
      width: 1.5rem;
    }
  }

  &__input-block {
    padding: .5rem;
    margin-top: .4rem;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 14px;
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: .7em 1em;
  }
  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #D31918;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    height: 2.625rem;
    width: 64%;
    margin: 0.5rem auto;
    cursor: pointer;
    &_isLoading {
      // background: #11541c;
      cursor: not-allowed;
    }
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
  &__result-header {
    height: 41px;
    background: #19295A;
    border: 1px solid #08153D;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.7rem;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    margin-bottom: 1.25rem;
  }
  &__result {
    padding: 0 0.4rem;
    box-sizing: border-box;
  }
  &__row {
    height: 2.5625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.7rem;
    font-weight: 700;
    font-size: 14px;
    background: #19295A;
    border: 1px solid #08153D;
    border-radius: 5px;
    margin-bottom: 8px;
  }

  &__time-input {
    width: 100%;
    min-height: 42px;
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    padding: 0.5rem 1rem;
    border: 1px solid #011810;
    border-radius: 5px;
    font-size: 0.875rem;
    background: #08153D;
    color: #fff;
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;
    margin-top: 5px;
  }
}
