.subheader {
  width: 100%;
  height: 45px;
  min-height: 45px;
  display: flex;
  background: #08153D;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  box-sizing: border-box;
  padding: 0 .7rem;
  margin-bottom: 1px;
  font-weight: bold;
  &__home {
    width: 2.286rem;
    height: 2.286rem;
  }
  &__image {
    width: 100%;
  }
}