.transaction-item {
  background: #19295A;
  border: 1px solid #08153D;
  border-radius: 5px;
  height: 2.5625rem;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 1rem;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 14px;
  &__column-amount {
    color: #fff;
  }
  &__column,
  &__column-amount {
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    &_type {
      &_center {
        text-align: center;
      }
      &_right {
        text-align: right;
      }
    }
    // &_colored {
    //   color: #00890d;
    // }
    &_type_right {
      max-width: 15%;
    }
  }
}
