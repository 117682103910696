.footer {

  width: 100%;
  display: flex;
  flex-direction: column;
  background: #090D27;
  align-items: center;
  padding: 0.5rem 1rem 2rem 1rem;
  box-sizing: border-box;
  margin-top: auto;
  &__item {
    width: 94%;
    height: 3rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    background: #19295A;
    border-radius: 5px;
    font-weight: 700;
    font-size: 0.875rem;
    color: #fff;
    margin-bottom: 0.3rem;
  }
  // &__image {
  //   width: 1.4rem;
  //   margin-right: 1rem;
  //   * {
  //     fill: #5E0885;
  //   }
  // }
  &__title {
    background: #08153D;
    border-radius: 5px;
    width: 100%;
    height: 2.9rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 1.5rem;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0.7rem;
  }
  &__phone {
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    text-align: center;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: bold;
    background: #5E0885;
    color: #fff;
  }
}