.locale {
  background: #090D27;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.75rem;
  &__title {
    background: #08153D;
    border-radius: 5px;
    width: 90%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
    margin-bottom: 8px;
  }
  &__item {
    width: 80%;
    height: 2.5625rem;
    display: flex;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.7rem;
    background: #08153D;
    border-radius: 5px;
    margin-bottom: 4px;
    
    &_active {
      border: 1px solid #6889EE;
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: .7em 1em;
    width: 65%;
    margin-top: 4.6875rem;
  }
  &__button {
    width: 100%;
    height: 2.625rem;
    background: #D31918;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
}