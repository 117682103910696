.user-edit {
  &__info {
    padding: 0.5rem;
    &-item {
      margin-bottom: 0.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-title {
      margin-bottom: 0.3rem;
      font-size: 14px;
      width: 92%;
      font-weight: 700;
      color: #fff;
    }
  }

  &__value {
    width: 92%;
    height: 2.625rem;
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    padding: 0.6rem;
    background: #FFFFFF;
    border: 1px solid #373737;
    border-radius: 5px;
    font-size: 1rem;
  }

  &__buttons {
    padding: 1.3rem;
  }

  &__buttons-bottom {
    display: flex;
    justify-content: space-between;
  }

  &__ban-button,
  &__unban-button {
    cursor: pointer;
    width: 47%;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 2.625rem;
  }

  &__unban-button {
    background: #19295A;
    border-radius: 5px;
  }

  &__ban-button {
    background: #08153D;
    border-radius: 5px;
  }

  &__payment-button {
    cursor: pointer;
    background: #D31918;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    height: 2.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 1rem;
  }

  &__reset-button {
    cursor: pointer;
    background: #08153D;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 1rem;
  }

  &__payment-block {
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__payment-button {
    margin: 0.8rem 4rem;
    width: 67%;
  }

  &__payment-item {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 92%;
  }

  &__payment-title {
    width: 100%;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0.3rem;
  }
}
