.app {
  width: 100%;
  height: 100%;
  background: #090D27;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &__plashka {
    width: 100%;
    position: fixed;
    z-index: 10000;
    display: flex;
  }
  &__plashka-img {
    width: 100%;
  }
  &__plashka-link {
    position: absolute;
    width: 50%;
    height: 100%;
    &:last-child {
      margin-left: 50%;
    }
  }
  &__plashka-close {
    width: 3rem;
    height: 3rem;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 10;
  }

}

@import url("https://fonts.googleapis.com/css?family=Roboto:100,400,700,900");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans::100,400,700,900&display=swap');

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open sans", sans-serif;
  background: #000;
}

html {
  font-size: 14px;
}

ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}