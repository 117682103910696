.button-mobile {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  border: none;
  outline: none;
  user-select: none;
  font-weight: 500;
  font-size: 0.875rem;
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  &:active {
    transform: translateY(2px);
  }

  &_borderRadius {
    &_default {
      border-radius: 5px;
    }
  }

  &_color {
    &_default {
      color: #fff;
      background: #d31918;
    }
  }
  &:hover {
    color: #fff;
    background: #d31918;
  }
  &:hover#{&}_color {
    &_default {
      color: #fff;
      background: #d31918;
    }
  }

  &_disabled {
    background-color: gray;
    cursor: not-allowed;
    box-shadow: none;
  }
}
