.input-date-mobile {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;

  & .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }
  & .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }
  & .react-datepicker__triangle {
    display: none;
  }

  &__custom-date-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem;
    background: #08153d;
    border-radius: 5px;
    box-sizing: border-box;

    &-calendar {
      width: 1.5rem;
      min-width: 1.5rem;
      height: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0.3125rem 0 0;
    }
    &-value {
      margin-right: auto;
      font-weight: 600;
      font-size: 0.875rem;
      color: #fff;
    }
    &-arrow {
      width: 1.875rem;
      min-width: 1.875rem;
      height: 1.875rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &_valid &__custom-date-input {
    /* border: 1px solid green; */
  }
}
